<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        depressed
        rounded
        v-bind="attrs"
        v-on="on">
        <v-icon
          left
          small>
          $plus
        </v-icon>
        {{ $t('agent.agent.btn.create') }}
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="isReadonly"
      lazy-validation
      @submit.prevent="submit()">
      <v-card
        class="app-card"
        tile>
        <v-card-title>
          <title-label :label="$t('agent.agent.createTitle')" />
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12">
              <agent-form
                v-model="form"
                :error="error" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <save-change-button
            :state="state"
            :label="$t('app.btn.save')"
            :disabled="!valid"
            @click="submit()" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AgentForm from '@/views/Agent/components/Agent/AgentForm.vue'
import { CREATE_RESELLER, CREATE_RESELLER_USER } from '@/resources/graphql'

export default {
  components: {
    AgentForm
  },
  data () {
    return {
      dialog: false,
      isFetching: false,
      valid: true,
      isDirty: false,
      state: 'ready',
      reseller: null,
      form: {
        resellerCode: null,
        picture: null,
        name: null,
        tierId: null,
        tel: null,
        lineId: null,
        facebook: null,
        instagram: null,
        resellerAddress: null,
        taxId: null,
        users: [{
          id: null,
          user: '',
          password: ''
        }]
      },
      error: {
        resellerCode: [],
        name: [],
        picture: [],
        tierId: [],
        tel: [],
        lineId: [],
        facebook: [],
        instagram: [],
        resellerAddress: [],
        taxId: [],
        users: {
          email: [],
          password: []
        }
      }
    }
  },
  computed: {
    isReadonly () {
      return this.state === 'loading'
    }
  },
  methods: {
    changeDialog () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.state = 'ready'
    },
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.error = {
          resellerCode: [],
          name: [],
          picture: [],
          tierId: [],
          tel: [],
          lineId: [],
          facebook: [],
          instagram: [],
          resellerAddress: [],
          taxId: [],
          users: {
            email: [],
            password: []
          }
        }
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: CREATE_RESELLER,
          variables: {
            input: {
              resellerCode: this.form.resellerCode,
              picture: this.form.picture,
              name: this.form.name,
              tierId: this.form.tierId,
              tel: this.form.tel,
              lineId: this.form.lineId,
              facebook: this.form.facebook,
              instagram: this.form.instagram,
              resellerAddress: this.form.resellerAddress,
              taxId: this.form.taxId
            }
          }
        })
        if (res) {
          const { data: { createReseller } } = res
          const users = this.form.users.filter((item) => !item.isDeleted)
          if (createReseller.errors && Object.keys(createReseller.errors).length) {
            this.state = 'error'
            this.error = createReseller.errors
          } else if (users.length) {
            const resUser = await this.$apollo.mutate({
              mutation: CREATE_RESELLER_USER,
              variables: {
                input: {
                  id: createReseller.reseller.id,
                  email: this.form.users[0].email,
                  password: this.form.users[0].password
                }
              }
            })
            if (resUser) {
              const { data: { createUserReseller } } = resUser
              if (createReseller.errors && Object.keys(createReseller.errors).length) {
                this.state = 'error'
                this.error.users = createUserReseller.errors
              } else {
                this.state = 'success'
                this.$emit('submit')
                this.$refs.form.reset()
                this.closeDialog()
              }
            }
          } else {
            this.state = 'success'
            this.$emit('submit')
            this.$refs.form.reset()
            this.closeDialog()
          }
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on">
        <v-icon>
          mdi-card-search-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card
      class="app-card"
      tile>
      <v-toolbar
        flat
        dense>
        <v-toolbar-title class="text-subtitle-1">
          {{ $t('agent.agent.infoTitle') }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          small
          @click="closeDialog()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col
            cols="10"
            offset="1">
            <div class="mb-8 text-center">
              <v-avatar
                class="profile"
                color="grey"
                size="164"
                tile>
                <v-img :src="itemResellerAvatar"></v-img>
              </v-avatar>
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <label class="primary--text">{{ $t('agent.agent.field.code.label') }} :</label> {{ itemResellerCode }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <label class="primary--text">{{ $t('agent.agent.field.name.label') }} :</label> {{ itemResellerName }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <label class="primary--text">{{ $t('agent.agent.field.reward.label') }} :</label> {{ itemResellerReward }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <label class="primary--text">{{ $t('agent.agent.field.shopType.label') }} :</label> {{ itemResellerShopType }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    itemResellerAvatar: {
      type: [String],
      default: ''
    },
    itemResellerCode: {
      type: [String],
      required: true
    },
    itemResellerName: {
      type: [String],
      required: true
    },
    itemResellerReward: {
      type: [String, Number],
      required: true
    },
    itemResellerShopType: {
      type: [String],
      required: true
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

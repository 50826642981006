<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        v-bind="attrs"
        v-on="on">
        <v-icon
          left
          small>
          mdi-plus-circle
        </v-icon>
        {{ $t('agent.tier.btn.createProduct') }}
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="isSubmitting"
      lazy-validation
      @submit.prevent="submit()">
      <v-card
        class="app-card"
        tile>
        <v-card-title>
          <title-label :label="$t('agent.tier.productTitle')" />
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              lg="7">
              <text-field-label
                :label="$t('agent.tier.field.nameProduct.label')"
                required />
              <v-autocomplete
                v-model="form.productId"
                :items="products.nodes"
                :placeholder="$t('agent.tier.field.nameProduct.placeholder')"
                :rules="rule.productId"
                :error-messages="error.productId"
                item-text="name"
                item-value="id"
                dense
                outlined
                required
                @update:search-input="debounceSearch($event)" />
            </v-col>
            <v-col
              cols="12"
              lg="5">
              <text-field-label
                :label="$t('agent.tier.field.priceProduct.label')"
                required />
              <v-text-field
                v-model.number="form.resellerPrice"
                :placeholder="$t('agent.tier.field.priceProduct.placeholder')"
                :rules="rule.resellerPrice"
                :error-messages="error.resellerPrice"
                type="number"
                dense
                outlined
                required />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()">
            {{ $t('agent.tier.btn.close') }}
          </v-btn>
          <save-change-button
            :state="state"
            :label="$t('agent.tier.btn.createProduct')"
            :disabled="!valid"
            @click="submit()" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { GET_PRODUCTS, CREATE_TIER_PRODUCT } from '@/resources/graphql'
import _ from 'lodash'

export default {
  apollo: {
    products: () => ({
      query: GET_PRODUCTS,
      variables () {
        return {
          perPage: 4000,
          q: this.q
        }
      }
    })
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemTierId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      valid: true,
      state: 'ready',
      q: '',
      products: {
        nodes: []
      },
      form: {
        productId: '',
        resellerPrice: ''
      },
      rule: {
        productId: [
          (v) => !!v || this.$t('app.validate.required')
        ],
        resellerPrice: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      },
      error: {
        productId: [],
        resellerPrice: []
      }
    }
  },
  computed: {
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  methods: {
    debounceSearch: _.debounce(function (value) {
      this.q = value
    }, 800),
    changeDialog () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.state = 'ready'
    },
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        this.error = {
          productId: [],
          resellerPrice: []
        }
        const res = await this.$apollo.mutate({
          mutation: CREATE_TIER_PRODUCT,
          variables: {
            input: {
              id: this.itemTierId,
              productId: this.form.productId,
              resellerPrice: this.form.resellerPrice
            }
          }
        })

        if (res) {
          const { data: { createTierProduct } } = res
          if (createTierProduct.errors && Object.keys(createTierProduct.errors).length) {
            this.state = 'error'
            this.error = createTierProduct.errors
          } else {
            this.state = 'success'
            this.$emit('submit')
            this.$refs.form.reset()
            this.closeDialog()
          }
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

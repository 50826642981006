var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", icon: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v(" mdi-card-search-outline ")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "app-card", attrs: { tile: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dense: "" } },
            [
              _c("v-toolbar-title", { staticClass: "text-subtitle-1" }, [
                _vm._v(" " + _vm._s(_vm.$t("agent.agent.infoTitle")) + " ")
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "10", offset: "1" } },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-8 text-center" },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "profile",
                              attrs: { color: "grey", size: "164", tile: "" }
                            },
                            [
                              _c("v-img", {
                                attrs: { src: _vm.itemResellerAvatar }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _c("label", { staticClass: "primary--text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("agent.agent.field.code.label")
                                    ) + " :"
                                  )
                                ]),
                                _vm._v(" " + _vm._s(_vm.itemResellerCode) + " ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _c("label", { staticClass: "primary--text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("agent.agent.field.name.label")
                                    ) + " :"
                                  )
                                ]),
                                _vm._v(" " + _vm._s(_vm.itemResellerName) + " ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _c("label", { staticClass: "primary--text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("agent.agent.field.reward.label")
                                    ) + " :"
                                  )
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.itemResellerReward) + " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _c("label", { staticClass: "primary--text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("agent.agent.field.shopType.label")
                                    ) + " :"
                                  )
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.itemResellerShopType) + " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
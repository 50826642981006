var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: { "max-width": "600px", scrollable: "" },
          on: {
            input: function($event) {
              return _vm.changeDialog($event)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: "primary",
                            depressed: "",
                            rounded: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { left: "", small: "" } }, [
                        _vm._v(" $plus ")
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("agent.tier.btn.create")) + " "
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        "v-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { readonly: _vm.isSubmitting, "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit()
            }
          },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "app-card", attrs: { tile: "" } },
            [
              _c(
                "v-card-title",
                [
                  _c("title-label", {
                    attrs: { label: _vm.$t("agent.agent.createTitle") }
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", small: "" },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-close ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("tier-form", {
                    model: {
                      value: _vm.form,
                      callback: function($$v) {
                        _vm.form = $$v
                      },
                      expression: "form"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("app.btn.cancel")) + " ")]
                  ),
                  _c("save-change-button", {
                    attrs: {
                      state: _vm.state,
                      label: _vm.$t("app.btn.save"),
                      disabled: !_vm.valid
                    },
                    on: {
                      click: function($event) {
                        return _vm.submit()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "app-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.tiers.nodes,
          page: _vm.page,
          "items-per-page": _vm.pageSize,
          loading: _vm.isFetching,
          "loader-height": "2",
          "fixed-header": "",
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.data-action-1",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("selected", item)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("agent.tier.btn.manage")) + " "
                      )
                    ]
                  )
                ]
              }
            },
            {
              key: "item.data-action-2",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("tier-remove", {
                        attrs: {
                          "item-tier-id": item.id,
                          "can-remove": !item.resellerCount
                        },
                        on: {
                          submit: function($event) {
                            return _vm.refetch()
                          }
                        }
                      }),
                      _c("tier-update", {
                        attrs: { "item-tier-id": item.id },
                        on: {
                          submit: function($event) {
                            return _vm.refetch()
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "no-data",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column justify-center py-16" },
                    [
                      _c("div", { staticClass: "mb-3 text-subtitle-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("app.table.emptyMessage", [
                                _vm.$t("agent.tier.title")
                              ])
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "div",
                        [
                          _c("tier-create", {
                            on: {
                              submit: function($event) {
                                return _vm.refetch()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("v-pagination", {
            attrs: { length: _vm.tiers.pagesCount },
            model: {
              value: _vm.page,
              callback: function($$v) {
                _vm.page = $$v
              },
              expression: "page"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
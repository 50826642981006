var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { staticClass: "pb-0", attrs: { cols: "12", sm: "6", md: "8" } },
        [
          _c("text-field-label", {
            attrs: {
              label: _vm.$t("agent.agent.field.code.label"),
              required: ""
            }
          }),
          _c("v-text-field", {
            attrs: {
              value: _vm.value.resellerCode,
              placeholder: _vm.$t("agent.agent.field.code.placeholder"),
              rules: _vm.rule.resellerCode,
              dense: "",
              outlined: "",
              required: ""
            },
            on: {
              input: function($event) {
                return _vm.change({ resellerCode: $event })
              }
            }
          }),
          _c("text-field-label", {
            attrs: {
              label: _vm.$t("agent.agent.field.name.label"),
              required: ""
            }
          }),
          _c("v-text-field", {
            attrs: {
              value: _vm.value.name,
              placeholder: _vm.$t("agent.agent.field.name.placeholder"),
              rules: _vm.rule.name,
              dense: "",
              outlined: "",
              required: ""
            },
            on: {
              input: function($event) {
                return _vm.change({ name: $event })
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "6", md: "4" } },
        [
          _c("upload", {
            staticClass: "ma-3",
            attrs: {
              value: _vm.value.pictureUrl,
              name: "pictureUrl",
              confirm: false
            },
            on: {
              submit: function($event) {
                return _vm.change({
                  picture: $event.file,
                  pictureUrl: $event.url
                })
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "pt-0", attrs: { cols: "12" } },
        [
          _c("text-field-label", {
            attrs: {
              label: _vm.$t("agent.agent.field.tier.label"),
              required: ""
            }
          }),
          _c("v-select", {
            attrs: {
              value: _vm.value.tierId,
              items: _vm.tiers.nodes,
              placeholder: _vm.$t("agent.agent.field.tier.placeholder"),
              rules: _vm.rule.tierId,
              "item-text": "name",
              "item-value": "id",
              "menu-props": { offsetY: true },
              attach: "",
              "single-line": "",
              dense: "",
              outlined: "",
              required: ""
            },
            on: {
              input: function($event) {
                return _vm.change({ tierId: $event })
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("text-field-label", {
            attrs: { label: _vm.$t("agent.agent.field.phonenumber.label") }
          }),
          _c("v-text-field", {
            attrs: {
              value: _vm.value.tel,
              placeholder: _vm.$t("agent.agent.field.phonenumber.placeholder"),
              dense: "",
              outlined: "",
              required: ""
            },
            on: {
              input: function($event) {
                return _vm.change({ tel: $event })
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("text-field-label", {
            attrs: { label: _vm.$t("agent.agent.field.line.label") }
          }),
          _c("v-text-field", {
            attrs: {
              value: _vm.value.lineId,
              placeholder: _vm.$t("agent.agent.field.line.placeholder"),
              dense: "",
              outlined: "",
              required: ""
            },
            on: {
              input: function($event) {
                return _vm.change({ lineId: $event })
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("text-field-label", {
            attrs: { label: _vm.$t("agent.agent.field.facebook.label") }
          }),
          _c("v-text-field", {
            attrs: {
              value: _vm.value.facebook,
              placeholder: _vm.$t("agent.agent.field.facebook.placeholder"),
              dense: "",
              outlined: "",
              required: ""
            },
            on: {
              input: function($event) {
                return _vm.change({ facebook: $event })
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("text-field-label", {
            attrs: { label: _vm.$t("agent.agent.field.instagram.label") }
          }),
          _c("v-text-field", {
            attrs: {
              value: _vm.value.instagram,
              placeholder: _vm.$t("agent.agent.field.instagram.placeholder"),
              dense: "",
              outlined: "",
              required: ""
            },
            on: {
              input: function($event) {
                return _vm.change({ instagram: $event })
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("text-field-label", {
            attrs: { label: _vm.$t("agent.agent.field.address.label") }
          }),
          _c("v-textarea", {
            attrs: {
              value: _vm.value.resellerAddress,
              placeholder: _vm.$t("agent.agent.field.address.placeholder"),
              dense: "",
              outlined: ""
            },
            on: {
              input: function($event) {
                return _vm.change({ resellerAddress: $event })
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("text-field-label", {
            attrs: { label: _vm.$t("agent.agent.field.tax.label") }
          }),
          _c("v-text-field", {
            attrs: {
              value: _vm.value.taxId,
              placeholder: _vm.$t("agent.agent.field.tax.placeholder"),
              dense: "",
              outlined: "",
              required: ""
            },
            on: {
              input: function($event) {
                return _vm.change({ taxId: $event })
              }
            }
          })
        ],
        1
      ),
      _c("v-col", { attrs: { cols: "12" } }, [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c("title-label", {
              attrs: { label: _vm.$t("agent.agent.field.user.label") }
            }),
            _c(
              "v-tooltip",
              {
                attrs: { right: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "mx-1",
                                attrs: {
                                  "x-small": "",
                                  icon: "",
                                  color: "primary"
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v(" mdi-help-circle-outline ")])],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(" " + _vm._s(_vm.$t("agent.agent.tooltipUser")) + " ")
                ])
              ]
            ),
            !_vm.users.length
              ? _c(
                  "v-btn",
                  {
                    staticClass: "mx-3",
                    attrs: { color: "primary", depressed: "", rounded: "" },
                    on: {
                      click: function($event) {
                        return _vm.addObjArray(_vm.value.users)
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { left: "", small: "" } }, [
                      _vm._v(" mdi-plus-circle ")
                    ]),
                    _vm._v(
                      " " + _vm._s(_vm.$t("agent.agent.btn.addUser")) + " "
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm.users.length
        ? _c(
            "v-col",
            { attrs: { cols: "12", lg: "5" } },
            [
              _c("text-field-label", {
                attrs: {
                  label: _vm.$t("agent.agent.field.userEmail.label"),
                  required: ""
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.users.length
        ? _c(
            "v-col",
            { attrs: { cols: "12", lg: "5" } },
            [
              _c("text-field-label", {
                attrs: {
                  label: _vm.$t("agent.agent.field.userPassword.label"),
                  required: ""
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.value.users, function(user, i) {
        return [
          !user.isDeleted
            ? _c(
                "v-col",
                { key: "user_email-" + i, attrs: { cols: "12", lg: "5" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      value: user.email,
                      placeholder: _vm.$t(
                        "agent.agent.field.userEmail.placeholder"
                      ),
                      rules: _vm.rule.email,
                      "error-messages": _vm.error.users.email,
                      disabled: user.id !== null,
                      dense: "",
                      outlined: "",
                      required: ""
                    },
                    on: {
                      input: function($event) {
                        _vm.change({
                          users: _vm.updateObjArray(_vm.value.users, i, {
                            email: $event
                          })
                        })
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !user.isDeleted
            ? _c(
                "v-col",
                { key: "user_password-" + i, attrs: { cols: "12", lg: "5" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      !user.id
                        ? _c("v-text-field", {
                            attrs: {
                              value: user.password,
                              placeholder: _vm.$t(
                                "agent.agent.field.userPassword.placeholder"
                              ),
                              rules: _vm.rule.password,
                              "error-messages": _vm.error.users.password,
                              type: "password",
                              dense: "",
                              outlined: "",
                              required: ""
                            },
                            on: {
                              input: function($event) {
                                _vm.change({
                                  users: _vm.updateObjArray(
                                    _vm.value.users,
                                    i,
                                    { password: $event }
                                  )
                                })
                              }
                            }
                          })
                        : _c("v-text-field", {
                            attrs: {
                              value: "xxxxxx",
                              placeholder: _vm.$t(
                                "agent.agent.field.userPassword.placeholder"
                              ),
                              type: "password",
                              disabled: "",
                              dense: "",
                              outlined: "",
                              required: ""
                            }
                          })
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          !user.isDeleted
            ? _c(
                "v-col",
                { key: "user_delete-" + i, attrs: { cols: "12", lg: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-3",
                      attrs: { color: "red", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.change({
                            users: _vm.updateObjArray(_vm.value.users, i, {
                              isDeleted: true
                            })
                          })
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-delete-outline")])],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { cols: "12" } },
                [
                  _c("toggle-form", {
                    attrs: {
                      text: _vm.$t("agent.option.field.hideStockFromReseller"),
                      state: _vm.state["hideStockFromReseller"]
                    },
                    on: {
                      input: function($event) {
                        return _vm.submit("hideStockFromReseller")
                      }
                    },
                    model: {
                      value: _vm.form.hideStockFromReseller,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "hideStockFromReseller", $$v)
                      },
                      expression: "form.hideStockFromReseller"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { cols: "12" } },
                [
                  _c("toggle-form", {
                    attrs: {
                      text: _vm.$t(
                        "agent.option.field.seperateResellerInvoiceNumber"
                      ),
                      state: _vm.state["seperateResellerInvoiceNumber"]
                    },
                    on: {
                      input: function($event) {
                        return _vm.submit("seperateResellerInvoiceNumber")
                      }
                    },
                    model: {
                      value: _vm.form.seperateResellerInvoiceNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "seperateResellerInvoiceNumber", $$v)
                      },
                      expression: "form.seperateResellerInvoiceNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { cols: "12" } },
                [
                  _c("toggle-form", {
                    attrs: {
                      text: _vm.$t("agent.option.field.bypassResellerTransfer"),
                      state: _vm.state["bypassResellerTransfer"]
                    },
                    on: {
                      input: function($event) {
                        return _vm.submit("bypassResellerTransfer")
                      }
                    },
                    model: {
                      value: _vm.form.bypassResellerTransfer,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "bypassResellerTransfer", $$v)
                      },
                      expression: "form.bypassResellerTransfer"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { cols: "12" } },
                [
                  _c("toggle-form", {
                    attrs: {
                      text: _vm.$t("agent.option.field.resellerFreeShipping"),
                      state: _vm.state["resellerFreeShipping"]
                    },
                    on: {
                      input: function($event) {
                        return _vm.submit("resellerFreeShipping")
                      }
                    },
                    model: {
                      value: _vm.form.resellerFreeShipping,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "resellerFreeShipping", $$v)
                      },
                      expression: "form.resellerFreeShipping"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { cols: "12" } },
                [
                  _c("toggle-form", {
                    attrs: {
                      text: _vm.$t(
                        "agent.option.field.showReselledPriceInInvoice"
                      ),
                      state: _vm.state["showReselledPriceInInvoice"]
                    },
                    on: {
                      input: function($event) {
                        return _vm.submit("showReselledPriceInInvoice")
                      }
                    },
                    model: {
                      value: _vm.form.showReselledPriceInInvoice,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "showReselledPriceInInvoice", $$v)
                      },
                      expression: "form.showReselledPriceInInvoice"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
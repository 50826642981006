<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dense
        outlined
        v-bind="attrs"
        v-on="on">
        {{ $t('agent.tier.btn.importExcel') }}
      </v-btn>
    </template>
    <v-card
      class="app-card"
      tile>
      <v-card-title>
        <title-label :label="$t('agent.tier.importExcelTitle')" />
        <v-spacer />
        <v-btn
          icon
          small
          @click="closeDialog()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="text-right pb-0">
            <a
              :href="link"
              target="_blank">
              {{ $t('agent.tier.linkDowload') }}
            </a>
          </v-col>
          <v-col cols="12">
            <upload
              :value="excelFileName"
              name="tierProductImportExcel"
              accept-type="excel"
              :confirm="false"
              @submit="upload($event)" />
          </v-col>
          <v-col
            cols="12">
            <div
              class="success--text"
              v-html="$t('agent.tier.importExcelRecommendHTML')">
            </div>
            <div class="error--text">
              {{ $t('agent.tier.importExcelDanger') }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog()">
          {{ $t('app.btn.cancel') }}
        </v-btn>
        <save-change-button
          :state="state"
          :label="$t('app.btn.save')"
          :disabled="!isDirty"
          @click="submit()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Upload from '@/components/Form/Upload.vue'
import { GET_TIER, IMPORT_TIER_EXCEL } from '@/resources/graphql'

export default {
  apollo: {
    tier: () => ({
      fetchPolicy: 'no-cache',
      query: GET_TIER,
      variables () {
        return {
          id: this.itemTierId
        }
      },
      result ({ data: { tier } }) {
        this.link = tier.productImportExcel
      }
    })
  },
  components: {
    Upload
  },
  props: {
    itemTierId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      state: 'ready',
      isDirty: false,
      link: null,
      excelFileName: null,
      excelFileObj: null
    }
  },
  computed: {
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    upload (obj) {
      this.isDirty = true
      this.excelFileName = obj.name
      this.excelFileObj = obj.file
    },
    async submit () {
      if (this.excelFileObj !== null) {
        this.state = 'loading'
        try {
          await this.$apollo.mutate({
            mutation: IMPORT_TIER_EXCEL,
            variables: {
              input: {
                id: this.itemTierId,
                excelFile: this.excelFileObj
              }
            },
            context: {
              hasUpload: true
            }
          })
          this.state = 'success'
          this.excelFileName = null
          this.excelFileObj = null
        } catch {
          this.state = 'error'
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

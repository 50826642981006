<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        v-bind="attrs"
        v-on="on">
        {{ $t('agent.tier.btn.pricePerQuantity') }}
      </v-btn>
    </template>
    <v-card
      class="app-card"
      tile>
      <v-card-title>
        <title-label :label="$t('agent.tier.pricePerQuantityTitle')" />
        <v-spacer />
        <v-btn
          icon
          small
          @click="closeDialog()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-form
        ref="form"
        v-model="valid"
        :readonly="isSubmitting"
        lazy-validation
        @submit.prevent="submit()">
        <v-card-text>
          <div class="mb-2 text-right">
            <v-btn
              v-if="packs.length > 0"
              color="primary"
              depressed
              rounded
              @click="create()">
              <v-icon
                left
                small>
                mdi-plus-circle
              </v-icon>
              {{ $t('agent.tier.btn.price') }}
            </v-btn>
          </div>
          <v-simple-table
            class="app-table"
            height="400px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('agent.tier.table.header.sequence') }}
                  </th>
                  <th class="text-left">
                    {{ $t('agent.tier.table.header.quantity') }}
                  </th>
                  <th class="text-left">
                    {{ $t('agent.tier.table.header.deliveryCost') }}
                  </th>
                  <th class="text-left">
                  </th>
                </tr>
                <tr v-if="packs.length === 0">
                  <td colspan="4">
                    <div class="d-flex flex-column justify-center py-16 text-center">
                      <text-field-label
                        :label="$t('app.table.emptyMessage', [$t('agent.tier.pricePerQuantityTitle')])"
                        class="mb-3 justify-center" />
                      <div>
                        <v-btn
                          color="primary"
                          depressed
                          rounded
                          @click="create()">
                          <v-icon
                            left
                            small>
                            mdi-plus-circle
                          </v-icon>
                          {{ $t('agent.tier.btn.price') }}
                        </v-btn>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in packs">
                  <tr
                    v-show="!item.isDeleted"
                    :key="`tb_product_pack-${index}`">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <v-text-field
                        v-model.number="item.quantity"
                        :rules="item.isDeleted ? [] : rule.quantity"
                        type="number"
                        min="0"
                        autofocus
                        hide-details
                        dense
                        outlined
                        :disabled="item.isDeleted"
                        @input="markAsDirty($event)" />
                    </td>
                    <td>
                      <v-text-field
                        v-model.number="item.price"
                        :rules="item.isDeleted ? [] : rule.price"
                        type="number"
                        min="0"
                        hide-details
                        dense
                        outlined
                        :disabled="item.isDeleted"
                        @input="markAsDirty($event)" />
                    </td>
                    <td>
                      <v-btn
                        :color="item.isDeleted ? 'error' : '#4d4d4d'"
                        icon
                        @click="remove(index)">
                        <v-icon>
                          {{ item.isDeleted ? ' mdi-delete-clock-outline' : ' mdi-delete-outline' }}
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <save-change-button
            :state="state"
            :label="$t('app.btn.save')"
            :disabled="!isDirty || !valid"
            @click="submit()" />
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  CREATE_QUANTITY_PRICE_TIER_PRODUCT,
  UPDATE_QUANTITY_PRICE_TIER_PRODUCT,
  DELETE_QUANTITY_PRICE_TIER_PRODUCT
} from '@/resources/graphql'

export default {
  props: {
    itemTierProductId: {
      type: [String, Number],
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      sequence: 1,
      state: 'ready',
      valid: true,
      isDirty: false,
      selected: null,
      rule: {
        quantity: [
          (v) => !!v || this.$t('app.validate.required')
        ],
        price: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      },
      packs: [...this.items]
    }
  },
  computed: {
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  watch: {
    items () {
      this.packs = [...this.items]
    }
  },
  methods: {
    changeDialog () {
      this.isDirty = false
      this.packs = [...this.items]
    },
    markAsDirty () {
      this.isDirty = true
      this.state = 'ready'
      this.error = {
        quantity: [],
        price: []
      }
    },
    create () {
      this.packs.push({ id: null, quantity: null, price: null })
      this.markAsDirty()
    },
    remove (index) {
      this.packs[index] = {
        ...this.packs[index],
        isDeleted: this.packs[index].isDeleted !== null ? !this.packs[index].isDeleted : true
      }
      this.packs = [
        ...this.packs
      ]
      this.markAsDirty()
    },
    closeDialog () {
      this.dialog = false
    },
    async mutateCreate (item) {
      await this.$apollo.mutate({
        mutation: CREATE_QUANTITY_PRICE_TIER_PRODUCT,
        variables: {
          input: {
            id: Number(this.itemTierProductId),
            quantity: item.quantity,
            price: item.price
          }
        }
      })
    },
    async mutateUpdate (item) {
      await this.$apollo.mutate({
        mutation: UPDATE_QUANTITY_PRICE_TIER_PRODUCT,
        variables: {
          input: {
            id: Number(this.itemTierProductId),
            quantityPriceId: item.id,
            quantity: item.quantity,
            price: item.price
          }
        }
      })
    },
    async mutateDelete (item) {
      await this.$apollo.mutate({
        mutation: DELETE_QUANTITY_PRICE_TIER_PRODUCT,
        variables: {
          input: {
            id: Number(this.itemTierProductId),
            quantityPriceId: Number(item.id)
          }
        }
      })
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        try {
          await this.packs.forEach(async (item) => {
            if (item.id && item.isDeleted) {
              await this.mutateDelete(item)
            } else if (item.id) {
              await this.mutateUpdate(item)
            } else if (!item.isDeleted) {
              await this.mutateCreate(item)
            }
            this.state = 'success'
            this.$emit('submit')
          })
        } catch {
          this.state = 'error'
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

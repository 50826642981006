<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on">
        <v-icon>
          mdi-square-edit-outline
        </v-icon>
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="isSubmitting"
      lazy-validation
      @submit.prevent="submit()">
      <v-card
        class="app-card"
        tile>
        <v-card-title>
          <title-label :label="$t('agent.tier.updateResellerPriceTitle')" />
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <text-field-label
                :label="$t('agent.tier.field.price.label')"
                required />
              <v-text-field
                v-model.number="form.resellerPrice"
                :placeholder="$t('agent.tier.field.price.placeholder')"
                :rules="rule.resellerPrice"
                :error-messages="error.resellerPrice"
                type="number"
                dense
                outlined
                required />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <save-change-button
            :state="state"
            :disabled="!valid"
            @click="submit()" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { UPDATE_TIER_PRODUCT } from '@/resources/graphql'

export default {
  props: {
    itemTierProductId: {
      type: [String, Number],
      required: true
    },
    itemProductId: {
      type: [String, Number],
      required: true
    },
    price: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      valid: true,
      state: 'ready',
      products: {
        nodes: []
      },
      form: {
        resellerPrice: this.price
      },
      rule: {
        resellerPrice: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      },
      error: {
        resellerPrice: []
      }
    }
  },
  computed: {
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  methods: {
    changeDialog () {
      this.state = 'ready'
      this.form.resellerPrice = this.price
    },
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        this.error = {
          resellerPrice: []
        }
        const res = await this.$apollo.mutate({
          mutation: UPDATE_TIER_PRODUCT,
          variables: {
            input: {
              id: this.itemTierProductId,
              productId: this.itemProductId,
              resellerPrice: this.form.resellerPrice
            }
          }
        })

        if (res) {
          const { data: { updateTierProduct } } = res
          if (updateTierProduct.errors && Object.keys(updateTierProduct.errors).length) {
            this.state = 'error'
            this.error = updateTierProduct.errors
          } else {
            this.state = 'success'
            this.$emit('submit')
            this.$refs.form.reset()
            this.closeDialog()
          }
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

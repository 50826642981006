var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "app-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.resellers.nodes,
          "items-per-page": _vm.pageSize,
          loading: _vm.isFetching,
          "custom-sort": _vm.sorting,
          "loader-height": "2",
          "fixed-header": "",
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.action-info",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("agent-info", {
                    attrs: {
                      "item-reseller-avatar": item.pictureUrl,
                      "item-reseller-code": item.resellerCode,
                      "item-reseller-name": item.name,
                      "item-reseller-reward": item.rewardValue,
                      "item-reseller-shop-type": item.resellerType
                    }
                  })
                ]
              }
            },
            {
              key: "item.data-action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("agent-update", {
                        attrs: { "item-reseller-id": item.id },
                        on: {
                          submit: function($event) {
                            return _vm.refetch()
                          }
                        }
                      }),
                      _c("agent-remove", {
                        attrs: { "item-reseller-id": item.id },
                        on: {
                          submit: function($event) {
                            return _vm.refetch()
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "no-data",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column justify-center py-16" },
                    [
                      _c("div", { staticClass: "mb-3 text-subtitle-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("app.table.emptyMessage", [
                                _vm.$t("agent.agent.title")
                              ])
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "div",
                        [
                          _c("agent-create", {
                            on: {
                              submit: function($event) {
                                return _vm.refetch()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("v-pagination", {
            attrs: { length: _vm.resellers.pagesCount },
            model: {
              value: _vm.page,
              callback: function($$v) {
                _vm.page = $$v
              },
              expression: "page"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <header-label
      :label="$t('agent.header')"
      prepend-icon="$g_agent" />
    <v-tabs
      v-model="tab"
      class="app-tab"
      show-arrows
      @change="deselect()">
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
        class="font-weight-regular">
        {{ item }}
      </v-tab>
      <v-spacer />
      <div class="d-flex justify-space-between align-center">
        <agent-remove-list v-if="tab === 0" />
        <agent-create
          v-if="tab === 0"
          @submit="resellerRefetch()" />
        <tier-create
          v-if="tab === 1 && !selected"
          @submit="tierRefetch()" />
        <tier-reseller-update
          v-if="tab === 1 && selected"
          :item-tier-id="selected.id"
          @submit="tierProductDataTable()" />
      </div>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :key="0">
        <v-card flat>
          <v-card-text>
            <div class="d-flex justify-space-between align-center mb-4">
              <title-label :label="$t('agent.agent.title')" />
              <v-spacer />
              <div class="flex-grow-1">
                <v-text-field
                  append-icon="mdi-magnify"
                  :placeholder="$t('app.form.search.placeholder')"
                  dense
                  hide-details
                  outlined
                  @input="search($event)" />
              </div>
            </div>
            <agent-data-table
              ref="ResellerDataTable"
              :q="resellerQuery" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="1">
        <v-card flat>
          <v-card-text>
            <template v-if="!selected">
              <title-label
                :label="$t('agent.tier.title')"
                class=" mb-4" />
              <tier-data-table
                ref="TierDataTable"
                @selected="select($event)" />
            </template>
            <tier-product-data-table
              v-else
              ref="TierProductDataTable"
              :item-tier-id="selected.id"
              @close="deselect()" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="2">
        <option-form />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import AgentRemoveList from '@/views/Agent/components/Agent/AgentRemoveList.vue'
import AgentCreate from '@/views/Agent/components/Agent/AgentCreate.vue'
import AgentDataTable from '@/views/Agent/components/Agent/AgentDataTable.vue'
import TierDataTable from '@/views/Agent/components/Tier/TierDataTable.vue'
import TierCreate from '@/views/Agent/components/Tier/TierCreate.vue'
import TierResellerUpdate from '@/views/Agent/components/Tier/TierResellerUpdate.vue'
import TierProductDataTable from '@/views/Agent/components/Tier/TierProductDataTable.vue'
import OptionForm from '@/views/Agent/components/Option/OptionForm.vue'

export default {
  name: 'Agent',
  components: {
    AgentRemoveList,
    AgentCreate,
    AgentDataTable,
    TierCreate,
    TierResellerUpdate,
    TierDataTable,
    TierProductDataTable,
    OptionForm
  },
  data () {
    return {
      tab: 0,
      tabs: [this.$t('agent.agent.tab'), this.$t('agent.tier.tab'), this.$t('agent.option.tab')],
      selected: null,
      resellerQuery: ''
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  mounted () {
    this.tab = this.$route.query.tab ? Number(this.$route.query.tab) : 0
  },
  methods: {
    resellerRefetch () {
      this.$refs.ResellerDataTable.refetch()
    },
    tierRefetch () {
      this.$refs.TierDataTable.refetch()
    },
    tierProductDataTable () {
      this.$refs.TierProductDataTable.refetch()
    },
    select (item) {
      this.selected = item
    },
    deselect () {
      this.selected = null
    },
    // eslint-disable-next-line func-names
    search: _.debounce(function (value) {
      this.resellerQuery = value
    }, 800)
  }
}
</script>

<style>

</style>

<style scoped>

</style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on">
        <v-icon>
          mdi-square-edit-outline
        </v-icon>
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="isReadonly"
      lazy-validation
      @submit.prevent="submit()">
      <v-card
        class="app-card"
        tile>
        <v-card-title>
          <title-label :label="$t('agent.agent.updateTitle')" />
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row
            v-if="isFetching"
            class="fill-height"
            align-content="center"
            justify="center">
            <v-col
              class="subtitle-1 text-center"
              cols="12">
              {{ $t('app.loading') }}
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              />
            </v-col>
          </v-row>
          <agent-form
            v-model="form"
            :error="error"
            @input="markAsDirty($event)" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <save-change-button
            :state="state"
            :label="$t('app.btn.save')"
            :disabled="isFetching || !isDirty || !valid"
            @click="submit()" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AgentForm from '@/views/Agent/components/Agent/AgentForm.vue'
import { GET_RESELLER, UPDATE_RESELLER, CREATE_RESELLER_USER, DELETE_RESELLER_USER } from '@/resources/graphql'

export default {
  apollo: {
    reseller: () => ({
      skip: true,
      fetchPolicy: 'no-cache',
      query: GET_RESELLER,
      variables () {
        return {
          id: this.itemResellerId
        }
      },
      watchLoading (isLoading) {
        this.isFetching = isLoading
      },
      result ({ data: { reseller } }) {
        this.form.resellerCode = reseller.resellerCode
        this.form.name = reseller.name
        this.form.pictureUrl = reseller.pictureUrl
        this.form.tierId = reseller.tier.id
        this.form.tel = reseller.tel
        this.form.lineId = reseller.lineId
        this.form.facebook = reseller.facebook
        this.form.instagram = reseller.instagram
        this.form.resellerAddress = reseller.resellerAddress
        this.form.taxId = reseller.taxId
        this.form.users = reseller.users
      }
    })
  },
  components: {
    AgentForm
  },
  props: {
    itemResellerId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      isFetching: false,
      valid: true,
      isDirty: false,
      state: 'ready',
      reseller: null,
      form: {
        resellerCode: null,
        pictureUrl: null,
        picture: null,
        name: null,
        tierId: null,
        tel: null,
        lineId: null,
        facebook: null,
        instagram: null,
        resellerAddress: null,
        taxId: null,
        users: []
      },
      error: {
        resellerCode: [],
        picture: [],
        name: [],
        tierId: [],
        tel: [],
        lineId: [],
        facebook: [],
        instagram: [],
        resellerAddress: [],
        taxId: [],
        users: {
          email: [],
          password: []
        }
      }
    }
  },
  computed: {
    isReadonly () {
      return this.state === 'loading'
    }
  },
  methods: {
    changeDialog (value) {
      if (this.state !== 'loading') {
        this.$apollo.queries.reseller.skip = !value
        if (value) {
          this.isDirty = false
          this.$apollo.queries.reseller.refresh()
        }
      }
    },
    closeDialog () {
      this.dialog = false
    },
    markAsDirty () {
      this.isDirty = true
      this.state = 'ready'
      this.error = {
        resellerCode: [],
        picture: [],
        name: [],
        tierId: [],
        tel: [],
        lineId: [],
        facebook: [],
        instagram: [],
        resellerAddress: [],
        taxId: [],
        users: {
          email: [],
          password: []
        }
      }
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: UPDATE_RESELLER,
          variables: {
            input: {
              id: Number(this.itemResellerId),
              resellerCode: this.form.resellerCode,
              name: this.form.name,
              tierId: this.form.tierId,
              tel: this.form.tel,
              lineId: this.form.lineId,
              facebook: this.form.facebook,
              instagram: this.form.instagram,
              resellerAddress: this.form.resellerAddress,
              taxId: this.form.taxId,
              picture: this.form.picture
            }
          },
          context: {
            hasUpload: true
          }
        })

        if (res) {
          const { data: { updateReseller } } = res
          if (updateReseller.errors && Object.keys(updateReseller.errors).length) {
            this.state = 'error'
            this.error = updateReseller.errors
          } else {
            const hasDeleted = this.form.users.some((item) => item.isDeleted)
            if (hasDeleted) {
              await this.$apollo.mutate({
                mutation: DELETE_RESELLER_USER,
                variables: {
                  input: {
                    id: Number(this.itemResellerId)
                  }
                }
              })
            }
            const users = this.form.users.filter((item) => !item.isDeleted)
            const user = users[0]
            if (user && user.id === null) {
              const resUser = await this.$apollo.mutate({
                mutation: CREATE_RESELLER_USER,
                variables: {
                  input: {
                    id: Number(this.itemResellerId),
                    email: user.email,
                    password: user.password
                  }
                }
              })
              if (resUser) {
                const { data: { createUserReseller } } = resUser
                if (createUserReseller.errors && Object.keys(createUserReseller.errors).length) {
                  this.state = 'error'
                  this.error.users = createUserReseller.errors
                }
              } else {
                this.state = 'success'
                this.$emit('submit')
                this.$apollo.queries.reseller.refresh()
              }
            } else {
              this.state = 'success'
              this.$emit('submit')
              this.$apollo.queries.reseller.refresh()
            }
          }
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

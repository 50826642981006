<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        depressed
        rounded
        v-bind="attrs"
        v-on="on">
        <v-icon
          left
          small>
          $plus
        </v-icon>
        {{ $t('agent.tier.btn.create') }}
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="isSubmitting"
      lazy-validation
      @submit.prevent="submit()">
      <v-card
        class="app-card"
        tile>
        <v-card-title>
          <title-label :label="$t('agent.agent.createTitle')" />
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <tier-form v-model="form" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <save-change-button
            :state="state"
            :label="$t('app.btn.save')"
            :disabled="!valid"
            @click="submit()" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import TierForm from '@/views/Agent/components/Tier/TierForm.vue'
import { CREATE_TIER } from '@/resources/graphql'

export default {
  components: {
    TierForm
  },
  data () {
    return {
      dialog: false,
      valid: true,
      state: 'ready',
      form: {
        name: ''
      }
    }
  },
  computed: {
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  methods: {
    changeDialog () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.state = 'ready'
    },
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: CREATE_TIER,
          variables: {
            input: {
              name: this.form.name,
              stockId: this.form.stockId
            }
          }
        })

        if (res) {
          const { data: { createTier } } = res
          if (createTier.errors && Object.keys(createTier.errors).length) {
            this.state = 'error'
          } else {
            this.state = 'success'
            this.$emit('submit')
            this.$refs.form.reset()
            this.closeDialog()
          }
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

<template>
  <div>
    <v-data-table
      class="app-table"
      :headers="headers"
      :items="tiers.nodes"
      :page="page"
      :items-per-page="pageSize"
      :loading="isFetching"
      loader-height="2"
      fixed-header
      hide-default-footer>
      <!-- <template v-slot:[`item.action-info`]="{ item }">
        <tier-update
          :item-tier-id="item.id"
          @submit="refetch()" />
      </template> -->
      <template v-slot:[`item.data-action-1`]="{ item }">
        <v-btn
          color="primary"
          outlined
          @click="$emit('selected', item)">
          {{ $t('agent.tier.btn.manage') }}
        </v-btn>
      </template>
      <template v-slot:[`item.data-action-2`]="{ item }">
        <div class="d-flex">
          <tier-remove
            :item-tier-id="item.id"
            :can-remove="!item.resellerCount"
            @submit="refetch()" />
          <tier-update
            :item-tier-id="item.id"
            @submit="refetch()" />
        </div>
      </template>
      <template v-slot:[`no-data`]>
        <div class="d-flex flex-column justify-center py-16">
          <div class="mb-3 text-subtitle-1">
            {{ $t('app.table.emptyMessage', [$t('agent.tier.title')]) }}
          </div>
          <div>
            <tier-create @submit="refetch()" />
          </div>
        </div>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="page"
        :length="tiers.pagesCount" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TierCreate from '@/views/Agent/components/Tier/TierCreate.vue'
import TierUpdate from '@/views/Agent/components/Tier/TierUpdate.vue'
import TierRemove from '@/views/Agent/components/Tier/TierRemove.vue'
import { GET_TIERS } from '@/resources/graphql'

export default {
  apollo: {
    tiers: () => ({
      fetchPolicy: 'no-cache',
      query: GET_TIERS,
      variables () {
        return {
          page: this.page,
          perPage: this.pageSize
        }
      },
      watchLoading (isLoading) {
        this.isFetching = isLoading
        this.setLoading(isLoading)
      },
      result ({ data: { tiers } }) {
        this.isFetching = false
        if (this.page > tiers.pagesCount) {
          this.page -= 1
        }
      }
    })
  },
  components: {
    TierCreate,
    TierUpdate,
    TierRemove
  },
  data () {
    return {
      dialog: false,
      isFetching: true,
      page: 1,
      pageSize: 10,
      headers: [
        { text: this.$t('agent.tier.table.header.tier'), value: 'name', sortable: false },
        { text: this.$t('agent.tier.table.header.stock'), value: 'stock.name', width: '150', sortable: false },
        { text: this.$t('agent.tier.table.header.quantity'), value: 'tierProducts.nodesCount', width: '150', sortable: false },
        { text: this.$t('agent.tier.table.header.userQuantity'), value: 'resellerCount', width: '180', sortable: false },
        { text: '', value: 'data-action-1', width: '200', sortable: false },
        { text: '', value: 'data-action-2', width: '200', sortable: false }
      ],
      tiers: {
        nodes: [],
        pagesCount: 1
      }
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    refetch () {
      this.$apollo.queries.tiers.refetch()
    }
  }
}
</script>

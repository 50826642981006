var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px", scrollable: "" },
      on: {
        input: function($event) {
          return _vm.changeDialog($event)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mr-3",
                      attrs: { color: "red", outlined: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" " + _vm._s(_vm.$t("agent.agent.btn.removed")) + " ")]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "app-card", attrs: { tile: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("title-label", {
                attrs: { label: _vm.$t("agent.agent.removeListTitle") }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "div",
                { staticClass: "ma-1" },
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: _vm.$t("app.form.search.placeholder"),
                      "append-icon": "mdi-magnify",
                      autofocus: "",
                      "hide-details": "",
                      dense: "",
                      outlined: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.search($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c("v-simple-table", {
                staticClass: "app-table",
                attrs: { height: "300px", "fixed-header": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "agent.agent.table.header.agentRemoved"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("th")
                          ]),
                          _vm.resellers.nodes.length === 0
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: 2 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column justify-center py-16 text-center"
                                    },
                                    [
                                      _c("text-field-label", {
                                        staticClass: "mb-3 justify-center",
                                        attrs: {
                                          label: _vm.$t(
                                            "app.table.emptyMessage",
                                            [
                                              _vm.$t(
                                                "agent.agent.table.header.agentRemoved"
                                              )
                                            ]
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.resellers.nodes, function(item) {
                            return _c(
                              "tr",
                              { key: "tb_reseller_removed-" + item.id },
                              [
                                _c("td", { staticClass: "text-left" }, [
                                  _vm._v(" " + _vm._s(item.name) + " ")
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c("agent-recovery", {
                                      attrs: { "item-reseller-id": item.id },
                                      on: {
                                        submit: function($event) {
                                          return _vm.refetch()
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("v-pagination", {
                    attrs: { length: _vm.resellers.pagesCount },
                    model: {
                      value: _vm.page,
                      callback: function($$v) {
                        _vm.page = $$v
                      },
                      expression: "page"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-label", {
        attrs: { label: _vm.$t("agent.header"), "prepend-icon": "$g_agent" }
      }),
      _c(
        "v-tabs",
        {
          staticClass: "app-tab",
          attrs: { "show-arrows": "" },
          on: {
            change: function($event) {
              return _vm.deselect()
            }
          },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _vm._l(_vm.tabs, function(item, index) {
            return _c(
              "v-tab",
              { key: index, staticClass: "font-weight-regular" },
              [_vm._v(" " + _vm._s(item) + " ")]
            )
          }),
          _c("v-spacer"),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between align-center" },
            [
              _vm.tab === 0 ? _c("agent-remove-list") : _vm._e(),
              _vm.tab === 0
                ? _c("agent-create", {
                    on: {
                      submit: function($event) {
                        return _vm.resellerRefetch()
                      }
                    }
                  })
                : _vm._e(),
              _vm.tab === 1 && !_vm.selected
                ? _c("tier-create", {
                    on: {
                      submit: function($event) {
                        return _vm.tierRefetch()
                      }
                    }
                  })
                : _vm._e(),
              _vm.tab === 1 && _vm.selected
                ? _c("tier-reseller-update", {
                    attrs: { "item-tier-id": _vm.selected.id },
                    on: {
                      submit: function($event) {
                        return _vm.tierProductDataTable()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            { key: 0 },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center mb-4"
                        },
                        [
                          _c("title-label", {
                            attrs: { label: _vm.$t("agent.agent.title") }
                          }),
                          _c("v-spacer"),
                          _c(
                            "div",
                            { staticClass: "flex-grow-1" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  placeholder: _vm.$t(
                                    "app.form.search.placeholder"
                                  ),
                                  dense: "",
                                  "hide-details": "",
                                  outlined: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.search($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("agent-data-table", {
                        ref: "ResellerDataTable",
                        attrs: { q: _vm.resellerQuery }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: 1 },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      !_vm.selected
                        ? [
                            _c("title-label", {
                              staticClass: " mb-4",
                              attrs: { label: _vm.$t("agent.tier.title") }
                            }),
                            _c("tier-data-table", {
                              ref: "TierDataTable",
                              on: {
                                selected: function($event) {
                                  return _vm.select($event)
                                }
                              }
                            })
                          ]
                        : _c("tier-product-data-table", {
                            ref: "TierProductDataTable",
                            attrs: { "item-tier-id": _vm.selected.id },
                            on: {
                              close: function($event) {
                                return _vm.deselect()
                              }
                            }
                          })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-tab-item", { key: 2 }, [_c("option-form")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
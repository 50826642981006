<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          lg="4">
          <v-breadcrumbs
            :items="breadcrumbs"
            class="px-0 py-2">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :class="{'link--active': !item.disabled}"
                :disabled="item.disabled"
                @click="item.onClick()">
                <v-icon
                  v-if="item.icon"
                  left
                  small>
                  {{ item.icon }}
                </v-icon>
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col
          cols="12"
          lg="8">
          <div class="d-flex justify-end align-center flex-column flex-sm-row">
            <div class="mb-1 mx-1">
              <v-text-field
                :placeholder="$t('app.form.search.placeholder')"
                append-icon="mdi-magnify"
                hide-details
                dense
                outlined
                @input="search($event)" />
            </div>
            <div class="mb-1 mx-1">
              <tier-product-create
                :items="tier.tierProducts.nodes"
                :item-tier-id="itemTierId"
                @submit="refetch()" />
            </div>
            <div class="mb-1 mx-1">
              <tier-product-import :item-tier-id="itemTierId" />
            </div>
            <div class="mb-1 mx-1">
              <tier-product-import-excel :item-tier-id="itemTierId" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      class="app-table"
      :headers="headers"
      :items="tier.tierProducts.nodes"
      :items-per-page="pageSize"
      :loading="isFetching"
      loader-height="2"
      fixed-header
      hide-default-footer>
      <template v-slot:[`item.resellerPrice`]="{ item }">
        {{ item.resellerPrice }}
        <tier-product-update
          :item-tier-product-id="item.id"
          :item-product-id="item.product.id"
          :price="item.resellerPrice"
          @submit="refetch()" />
      </template>
      <template v-slot:[`item.data-action-1`]="{ item }">
        <tier-product-pack
          :item-tier-product-id="item.id"
          :items="item.quantityPrices"
          @submit="refetch()" />
      </template>
      <template v-slot:[`item.data-action-2`]="{ item }">
        <div class="d-flex">
          <tier-product-remove
            :item-tier-product-id="item.id"
            @submit="refetch()" />
        </div>
      </template>
      <template v-slot:[`no-data`]>
        <div class="d-flex flex-column justify-center py-16">
          <div class="mb-3 text-subtitle-1">
            {{ $t('app.table.emptyMessage', [$t('agent.tier.manageTitle')]) }}
          </div>
          <div>
            <tier-product-create
              :items="tier.tierProducts.nodes"
              :item-tier-id="itemTierId"
              @submit="refetch()" />
          </div>
        </div>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      :length="tier.tierProducts.pagesCount">
    </v-pagination>
  </div>
</template>

<script>
import _ from 'lodash'
import TierProductCreate from '@/views/Agent/components/Tier/TierProductCreate.vue'
import TierProductUpdate from '@/views/Agent/components/Tier/TierProductUpdate.vue'
import TierProductImport from '@/views/Agent/components/Tier/TierProductImport.vue'
import TierProductImportExcel from '@/views/Agent/components/Tier/TierProductImportExcel.vue'
import TierProductPack from '@/views/Agent/components/Tier/TierProductPack.vue'
import TierProductRemove from '@/views/Agent/components/Tier/TierProductRemove.vue'
import { GET_TIER_PRODUCTS } from '@/resources/graphql'

export default {
  apollo: {
    tier: () => ({
      fetchPolicy: 'no-cache',
      query: GET_TIER_PRODUCTS,
      variables () {
        return {
          id: this.itemTierId,
          page: this.page,
          perPage: this.pageSize,
          q: this.q
        }
      },
      watchLoading (isLoading) {
        this.isFetching = isLoading
      },
      result ({ data: { tier } }) {
        if (this.page > tier.tierProducts.pagesCount) {
          this.page -= 1
        }
        this.breadcrumbs = [{
          text: this.$t('agent.tier.title'),
          disabled: false,
          icon: 'mdi-account-group',
          onClick: () => this.$emit('close')
        }, {
          text: `${this.$t('agent.tier.manageTitle')} ${tier.name}`,
          disabled: true,
          href: ''
        }]
      }
    })
  },
  components: {
    TierProductCreate,
    TierProductUpdate,
    TierProductImport,
    TierProductImportExcel,
    TierProductPack,
    TierProductRemove
  },
  props: {
    itemTierId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      isFetching: false,
      q: '',
      page: 1,
      pageSize: 10,
      headers: [
        { text: this.$t('agent.tier.table.header.codeProduct'), value: 'product.code', sortable: false },
        { text: this.$t('agent.tier.table.header.nameProduct'), value: 'product.name', sortable: false },
        { text: this.$t('agent.tier.table.header.retailPrice'), value: 'product.price', width: '150', sortable: false },
        { text: this.$t('agent.tier.table.header.agentPrice'), value: 'resellerPrice', width: '150', sortable: false },
        { text: '', value: 'data-action-1', width: '200', sortable: false },
        { text: '', value: 'data-action-2', width: '200', sortable: false }
      ],
      tier: {
        name: '',
        tierProducts: {
          nodes: [],
          pagesCount: 1
        }
      },
      breadcrumbs: [
        {
          text: this.$t('agent.tier.title'),
          disabled: false,
          icon: 'mdi-account-group',
          onClick: () => this.$emit('close')
        }
      ]
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.tier.refetch()
    },
    search: _.debounce(function (value) {
      this.q = value
    }, 800)
  }
}
</script>

<style scoped>
  .link--active {
    color: var(--v-primary-base);
    cursor: pointer;
  }
</style>

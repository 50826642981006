<template>
  <v-row dense>
    <v-col cols="6">
      <text-field-label
        :label="$t('agent.tier.field.name.label')"
        required />
      <v-text-field
        :value="value.name"
        :placeholder="$t('agent.tier.field.name.placeholder')"
        :rules="rule.name"
        dense
        outlined
        required
        @input="changeValue({ name: $event })" />
    </v-col>
    <v-col cols="6">
      <text-field-label
        :label="$t('agent.tier.field.stock.label')"
        required />
      <v-autocomplete
        v-model="value.stockId"
        :items="stocks.nodes"
        :placeholder="$t('agent.tier.field.stock.placeholder')"
        :rules="rule.stockId"
        item-text="name"
        item-value="id"
        dense
        outlined
        required
        @input="changeValue({ stockId: $event })" />
    </v-col>
  </v-row>
</template>

<script>
import { GET_STOCKS } from '@/resources/graphql'

export default {
  apollo: {
    stocks: () => ({
      fetchPolicy: 'no-cache',
      query: GET_STOCKS,
      variables () {
        return {
          page: 1,
          perPage: 1000
        }
      }
    })
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        name: null,
        stockId: null
      })
    }
  },
  data () {
    return {
      stocks: {
        nodes: []
      },
      rule: {
        name: [
          (v) => !!v || this.$t('app.validate.required')
        ],
        stockId: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      }
    }
  },
  methods: {
    changeValue (newVal) {
      this.$emit('input', { ...this.value, ...newVal })
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-3"
        color="red"
        v-bind="attrs"
        outlined
        v-on="on">
        {{ $t('agent.agent.btn.removed') }}
      </v-btn>
    </template>
    <v-card
      class="app-card"
      tile>
      <v-card-title>
        <title-label :label="$t('agent.agent.removeListTitle')" />
        <v-spacer />
        <v-btn
          icon
          small
          @click="closeDialog()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="ma-1">
          <v-text-field
            :placeholder="$t('app.form.search.placeholder')"
            append-icon="mdi-magnify"
            autofocus
            hide-details
            dense
            outlined
            @input="search($event)" />
        </div>
        <v-simple-table
          class="app-table"
          height="300px"
          fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t('agent.agent.table.header.agentRemoved') }}
                </th>
                <th></th>
              </tr>
              <tr v-if="resellers.nodes.length === 0">
                <td :colspan="2">
                  <div class="d-flex flex-column justify-center py-16 text-center">
                    <text-field-label
                      :label="$t('app.table.emptyMessage', [$t('agent.agent.table.header.agentRemoved')])"
                      class="mb-3 justify-center" />
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item) in resellers.nodes"
                :key="`tb_reseller_removed-${item.id}`">
                <td class="text-left">
                  {{ item.name }}
                </td>
                <td>
                  <agent-recovery
                    :item-reseller-id="item.id"
                    @submit="refetch()" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="resellers.pagesCount" />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import AgentRecovery from '@/views/Agent/components/Agent/AgentRecovery.vue'
import { GET_RESELLERS } from '@/resources/graphql'

export default {
  apollo: {
    resellers: () => ({
      skip: true,
      fetchPolicy: 'no-cache',
      query: GET_RESELLERS,
      variables () {
        return {
          q: this.q,
          page: this.page,
          perPage: this.pageSize,
          activated: false
        }
      },
      watchLoading (isLoading) {
        this.isFetching = isLoading
      },
      result ({ data: { resellers } }) {
        if (this.page > resellers.pagesCount) {
          this.page -= 1
        }
      }
    })
  },
  components: {
    AgentRecovery
  },
  data () {
    return {
      dialog: false,
      q: '',
      page: 1,
      pageSize: 10,
      isFetching: false,
      resellers: {
        nodes: []
      }
    }
  },
  methods: {
    changeDialog (value) {
      this.$apollo.queries.resellers.skip = !value
      if (value) {
        this.refetch()
      }
    },
    refetch () {
      this.$apollo.queries.resellers.refresh()
    },
    closeDialog () {
      this.dialog = false
    },
    // eslint-disable-next-line func-names
    search: _.debounce(function (value) {
      this.q = value
    }, 800)
  }
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on">
        <v-icon>
          mdi-square-edit-outline
        </v-icon>
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="isReadonly"
      lazy-validation
      @submit.prevent="submit()">
      <v-card
        class="app-card"
        tile>
        <v-card-title>
          <title-label :label="$t('agent.agent.updateTitle')" />
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row
            v-if="isFetching"
            class="fill-height"
            align-content="center"
            justify="center">
            <v-col
              class="subtitle-1 text-center"
              cols="12">
              {{ $t('app.loading') }}
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              />
            </v-col>
          </v-row>
          <tier-form
            v-model="form"
            @input="markAsDirty($event)" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <save-change-button
            :state="state"
            :label="$t('app.btn.save')"
            :disabled="isFetching || !isDirty || !valid"
            @click="submit()" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import TierForm from '@/views/Agent/components/Tier/TierForm.vue'
import { GET_TIER, UPDATE_TIER } from '@/resources/graphql'

export default {
  apollo: {
    tier: () => ({
      skip: true,
      fetchPolicy: 'no-cache',
      query: GET_TIER,
      variables () {
        return {
          id: this.itemTierId
        }
      },
      result ({ data: { tier } }) {
        this.form.name = tier.name
        this.form.stockId = tier.stock.id
      }
    })
  },
  components: {
    TierForm
  },
  props: {
    itemTierId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      isFetching: false,
      valid: true,
      isDirty: false,
      state: 'ready',
      tier: null,
      form: {
        name: '',
        stockId: null
      }
    }
  },
  computed: {
    isReadonly () {
      return this.state === 'loading'
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    changeDialog (value) {
      if (this.state !== 'loading') {
        this.$apollo.queries.tier.skip = !value
        if (value) {
          this.isDirty = false
          this.$apollo.queries.tier.refetch()
        }
      }
    },
    markAsDirty () {
      this.isDirty = true
      this.state = 'ready'
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: UPDATE_TIER,
          variables: {
            input: {
              id: Number(this.itemTierId),
              name: this.form.name,
              stockId: this.form.stockId
            }
          }
        })

        if (res) {
          const { data: { updateTier } } = res

          if (updateTier.errors && Object.keys(updateTier.errors).length) {
            this.state = 'error'
          } else {
            this.state = 'success'
            this.$emit('submit')
          }
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", outlined: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" " + _vm._s(_vm.$t("agent.agent.btn.recovery")) + " ")]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "app-card", attrs: { tile: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dense: "" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-title", { staticClass: "headline font-weight-regular" }, [
            _vm._v(" " + _vm._s(_vm.$t("app.dialog.recovery.title")) + " ")
          ]),
          _c("v-card-text", [
            _vm._v(
              " " + _vm._s(_vm.$t("app.dialog.recovery.description")) + " "
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.btn.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    depressed: "",
                    rounded: "",
                    disabled: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.submit($event)
                    }
                  }
                },
                [
                  _vm.loading
                    ? _c("v-progress-circular", {
                        staticClass: "mr-2",
                        attrs: { size: "16", width: "2", indeterminate: "" }
                      })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.$t("agent.agent.btn.recovery")) + " ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
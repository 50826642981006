<template>
  <v-btn
    color="light-green accent-4"
    depressed
    rounded
    :dark="!isSubmitting"
    :disabled="isSubmitting"
    @click="submit()">
    <v-progress-circular
      v-if="isSubmitting"
      class="mr-2"
      size="16"
      width="2"
      indeterminate />
    <v-icon
      v-else
      left>
      {{ icon }}
    </v-icon>
    {{ $t('agent.tier.btn.update') }}
  </v-btn>
</template>

<script>
import { UPDATE_ALLRESELLERINTIER } from '@/resources/graphql'

export default {
  props: {
    itemTierId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      valid: true,
      state: 'ready'
    }
  },
  computed: {
    isSubmitting () {
      return this.state === 'loading'
    },
    icon () {
      switch (this.state) {
        case 'success':
          return 'mdi-check'
        case 'error':
          return 'mdi-close'
        default:
          return 'mdi-refresh'
      }
    }
  },
  methods: {
    async submit () {
      this.state = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_ALLRESELLERINTIER,
        variables: {
          input: {
            id: Number(this.itemTierId)
          }
        }
      })

      if (res) {
        const { data: { updateAllResellerInTier } } = res
        if (updateAllResellerInTier.errors && Object.keys(updateAllResellerInTier.errors).length) {
          this.state = 'error'
        } else {
          this.state = 'success'
        }
        setTimeout(() => {
          this.state = 'ready'
        }, 5000)
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

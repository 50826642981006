<template>
  <v-card flat>
    <v-card-text>
      <v-row no-gutters>
        <v-col
          cols="12"
          class="py-1">
          <toggle-form
            v-model="form.hideStockFromReseller"
            :text="$t('agent.option.field.hideStockFromReseller')"
            :state="state['hideStockFromReseller']"
            @input="submit('hideStockFromReseller')" />
        </v-col>
        <v-col
          cols="12"
          class="py-1">
          <toggle-form
            v-model="form.seperateResellerInvoiceNumber"
            :text="$t('agent.option.field.seperateResellerInvoiceNumber')"
            :state="state['seperateResellerInvoiceNumber']"
            @input="submit('seperateResellerInvoiceNumber')" />
        </v-col>
        <v-col
          cols="12"
          class="py-1">
          <toggle-form
            v-model="form.bypassResellerTransfer"
            :text="$t('agent.option.field.bypassResellerTransfer')"
            :state="state['bypassResellerTransfer']"
            @input="submit('bypassResellerTransfer')" />
        </v-col>
        <v-col
          cols="12"
          class="py-1">
          <toggle-form
            v-model="form.resellerFreeShipping"
            :text="$t('agent.option.field.resellerFreeShipping')"
            :state="state['resellerFreeShipping']"
            @input="submit('resellerFreeShipping')" />
        </v-col>
        <v-col
          cols="12"
          class="py-1">
          <toggle-form
            v-model="form.showReselledPriceInInvoice"
            :text="$t('agent.option.field.showReselledPriceInInvoice')"
            :state="state['showReselledPriceInInvoice']"
            @input="submit('showReselledPriceInInvoice')" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { GET_SETTING_RESELLER, UPDATE_SETTING } from '@/resources/graphql'

export default {
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_RESELLER,
      watchLoading (isLoading) {
        this.setLoading(isLoading)
      },
      result ({ data: { setting } }) {
        this.shopId = setting.id
        this.form.hideStockFromReseller = setting.hideStockFromReseller
        this.form.seperateResellerInvoiceNumber = setting.seperateResellerInvoiceNumber
        this.form.bypassResellerTransfer = setting.bypassResellerTransfer
        this.form.resellerFreeShipping = setting.resellerFreeShipping
        this.form.showReselledPriceInInvoice = setting.showReselledPriceInInvoice
      }
    })
  },
  data () {
    return {
      dialog: false,
      shopId: null,
      state: {
        hideStockFromReseller: 'ready',
        seperateResellerInvoiceNumber: 'ready',
        bypassResellerTransfer: 'ready',
        resellerFreeShipping: 'ready',
        showReselledPriceInInvoice: 'ready'
      },
      form: {
        hideStockFromReseller: null,
        seperateResellerInvoiceNumber: null,
        bypassResellerTransfer: null,
        resellerFreeShipping: null,
        showReselledPriceInInvoice: null
      }
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

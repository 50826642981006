var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("text-field-label", {
            attrs: {
              label: _vm.$t("agent.tier.field.name.label"),
              required: ""
            }
          }),
          _c("v-text-field", {
            attrs: {
              value: _vm.value.name,
              placeholder: _vm.$t("agent.tier.field.name.placeholder"),
              rules: _vm.rule.name,
              dense: "",
              outlined: "",
              required: ""
            },
            on: {
              input: function($event) {
                return _vm.changeValue({ name: $event })
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("text-field-label", {
            attrs: {
              label: _vm.$t("agent.tier.field.stock.label"),
              required: ""
            }
          }),
          _c("v-autocomplete", {
            attrs: {
              items: _vm.stocks.nodes,
              placeholder: _vm.$t("agent.tier.field.stock.placeholder"),
              rules: _vm.rule.stockId,
              "item-text": "name",
              "item-value": "id",
              dense: "",
              outlined: "",
              required: ""
            },
            on: {
              input: function($event) {
                return _vm.changeValue({ stockId: $event })
              }
            },
            model: {
              value: _vm.value.stockId,
              callback: function($$v) {
                _vm.$set(_vm.value, "stockId", $$v)
              },
              expression: "value.stockId"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
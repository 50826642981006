<template>
  <div>
    <v-data-table
      class="app-table"
      :headers="headers"
      :items="resellers.nodes"
      :items-per-page="pageSize"
      :loading="isFetching"
      :custom-sort="sorting"
      loader-height="2"
      fixed-header
      hide-default-footer>
      <template v-slot:[`item.action-info`]="{ item }">
        <agent-info
          :item-reseller-avatar="item.pictureUrl"
          :item-reseller-code="item.resellerCode"
          :item-reseller-name="item.name"
          :item-reseller-reward="item.rewardValue"
          :item-reseller-shop-type="item.resellerType" />
      </template>
      <template v-slot:[`item.data-action`]="{ item }">
        <div class="d-flex">
          <agent-update
            :item-reseller-id="item.id"
            @submit="refetch()" />
          <agent-remove
            :item-reseller-id="item.id"
            @submit="refetch()" />
        </div>
      </template>
      <template v-slot:[`no-data`]>
        <div
          class="d-flex flex-column justify-center py-16">
          <div class="mb-3 text-subtitle-1">
            {{ $t('app.table.emptyMessage', [$t('agent.agent.title')]) }}
          </div>
          <div>
            <agent-create @submit="refetch()" />
          </div>
        </div>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="page"
        :length="resellers.pagesCount" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import humps from 'humps'
import AgentCreate from '@/views/Agent/components/Agent/AgentCreate.vue'
import AgentInfo from '@/views/Agent/components/Agent/AgentInfo.vue'
import AgentUpdate from '@/views/Agent/components/Agent/AgentUpdate.vue'
import AgentRemove from '@/views/Agent/components/Agent/AgentRemove.vue'
import { GET_RESELLERS } from '@/resources/graphql'

export default {
  apollo: {
    resellers: () => ({
      fetchPolicy: 'no-cache',
      query: GET_RESELLERS,
      variables () {
        return {
          page: this.page,
          perPage: this.pageSize,
          q: this.q,
          orderby: this.orderby,
          activated: true
        }
      },
      watchLoading (isLoading) {
        this.isFetching = isLoading
        this.setLoading(isLoading)
      },
      result ({ data: { resellers } }) {
        this.isFetching = false
        if (this.page > resellers.pagesCount) {
          this.page -= 1
        }
      }
    })
  },
  components: {
    AgentCreate,
    AgentInfo,
    AgentUpdate,
    AgentRemove
  },
  props: {
    q: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      page: 1,
      pageSize: 10,
      isFetching: true,
      orderby: 'id_DESC',
      headers: [
        { text: this.$t('agent.agent.table.header.code'), value: 'resellerCode', sortable: true },
        { text: '', value: 'action-info', width: '70', sortable: false },
        { text: this.$t('agent.agent.table.header.name'), value: 'name', sortable: true },
        { text: this.$t('agent.agent.table.header.tier'), value: 'tier.name', sortable: false },
        { text: this.$t('agent.agent.table.header.reward'), value: 'rewardValue', sortable: false },
        { text: '', value: 'data-action', width: '200', sortable: false }
      ],
      resellers: {
        nodes: [],
        pagesCount: 1
      }
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    refetch () {
      this.$apollo.queries.resellers.refetch()
    },
    sorting (items, sortBy, sortDesc) {
      let sortName = 'id'
      let sortType = 'DESC'
      if (sortBy.length) {
        sortName = humps.decamelize(sortBy[0])
      }
      if (sortDesc.length) {
        sortType = sortDesc[0] ? 'DESC' : 'ASC'
      }
      this.orderby = `${sortName}_${sortType}`
      return items
    }
  }
}
</script>

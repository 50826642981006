var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: {
        color: "light-green accent-4",
        depressed: "",
        rounded: "",
        dark: !_vm.isSubmitting,
        disabled: _vm.isSubmitting
      },
      on: {
        click: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _vm.isSubmitting
        ? _c("v-progress-circular", {
            staticClass: "mr-2",
            attrs: { size: "16", width: "2", indeterminate: "" }
          })
        : _c("v-icon", { attrs: { left: "" } }, [
            _vm._v(" " + _vm._s(_vm.icon) + " ")
          ]),
      _vm._v(" " + _vm._s(_vm.$t("agent.tier.btn.update")) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
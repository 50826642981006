<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dense
        outlined
        v-bind="attrs"
        v-on="on">
        {{ $t('agent.tier.btn.importProduct') }}
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="isSubmitting"
      lazy-validation
      @submit.prevent="submit()">
      <v-card
        class="app-card"
        tile>
        <v-card-title>
          <title-label :label="$t('agent.tier.importTitle')" />
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12">
              <text-field-label
                :label="$t('agent.tier.field.wholesalePrice.label')"
                required />
              <v-select
                v-model.number="form.wholesalePrice"
                :items="wholesalePriceItems"
                :placeholder="$t('agent.tier.field.wholesalePrice.placeholder')"
                :rules="rule.wholesalePrice"
                :error-messages="error.wholesalePrice"
                dense
                outlined
                required />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <save-change-button
            :state="state"
            :label="$t('app.btn.save')"
            :disabled="!valid"
            @click="submit()" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { IMPORT_TIER_ALLPRODUCT } from '@/resources/graphql/mutation/Tier'

export default {
  props: {
    itemTierId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      valid: true,
      state: 'ready',
      form: {
        wholesalePrice: 1
      },
      rule: {
        wholesalePrice: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      },
      error: {
        wholesalePrice: []
      },
      wholesalePriceItems: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
  },
  computed: {
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        this.error = {
          wholesalePrice: []
        }
        const res = await this.$apollo.mutate({
          mutation: IMPORT_TIER_ALLPRODUCT,
          variables: {
            input: {
              id: this.itemTierId,
              wholesalePrice: this.form.wholesalePrice
            }
          }
        })

        if (res) {
          const { data: { tierImportAllProduct } } = res
          if (tierImportAllProduct.errors && Object.keys(tierImportAllProduct.errors).length) {
            this.state = 'error'
            this.error = tierImportAllProduct.errors
          } else {
            this.state = 'success'
            this.$emit('submit')
            this.closeDialog()
          }
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

import { render, staticRenderFns } from "./AgentRemoveList.vue?vue&type=template&id=05efc028&"
import script from "./AgentRemoveList.vue?vue&type=script&lang=js&"
export * from "./AgentRemoveList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VDialog,VIcon,VPagination,VSimpleTable,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05efc028')) {
      api.createRecord('05efc028', component.options)
    } else {
      api.reload('05efc028', component.options)
    }
    module.hot.accept("./AgentRemoveList.vue?vue&type=template&id=05efc028&", function () {
      api.rerender('05efc028', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Agent/components/Agent/AgentRemoveList.vue"
export default component.exports
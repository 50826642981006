<template>
  <v-row dense>
    <v-col
      cols="12"
      sm="6"
      md="8"
      class="pb-0">
      <text-field-label
        :label="$t('agent.agent.field.code.label')"
        required />
      <v-text-field
        :value="value.resellerCode"
        :placeholder="$t('agent.agent.field.code.placeholder')"
        :rules="rule.resellerCode"
        dense
        outlined
        required
        @input="change({ resellerCode: $event })" />
      <text-field-label
        :label="$t('agent.agent.field.name.label')"
        required />
      <v-text-field
        :value="value.name"
        :placeholder="$t('agent.agent.field.name.placeholder')"
        :rules="rule.name"
        dense
        outlined
        required
        @input="change({ name: $event })" />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4">
      <upload
        :value="value.pictureUrl"
        name="pictureUrl"
        class="ma-3"
        :confirm="false"
        @submit="change({ picture: $event.file, pictureUrl: $event.url })" />
    </v-col>
    <v-col
      cols="12"
      class="pt-0">
      <text-field-label
        :label="$t('agent.agent.field.tier.label')"
        required />
      <v-select
        :value="value.tierId"
        :items="tiers.nodes"
        :placeholder="$t('agent.agent.field.tier.placeholder')"
        :rules="rule.tierId"
        item-text="name"
        item-value="id"
        :menu-props="{ offsetY: true }"
        attach
        single-line
        dense
        outlined
        required
        @input="change({ tierId: $event })" />
    </v-col>
    <v-col cols="12">
      <text-field-label :label="$t('agent.agent.field.phonenumber.label')" />
      <v-text-field
        :value="value.tel"
        :placeholder="$t('agent.agent.field.phonenumber.placeholder')"
        dense
        outlined
        required
        @input="change({ tel: $event })" />
    </v-col>
    <v-col cols="12">
      <text-field-label :label="$t('agent.agent.field.line.label')" />
      <v-text-field
        :value="value.lineId"
        :placeholder="$t('agent.agent.field.line.placeholder')"
        dense
        outlined
        required
        @input="change({ lineId: $event })" />
    </v-col>
    <v-col cols="12">
      <text-field-label :label="$t('agent.agent.field.facebook.label')" />
      <v-text-field
        :value="value.facebook"
        :placeholder="$t('agent.agent.field.facebook.placeholder')"
        dense
        outlined
        required
        @input="change({ facebook: $event })" />
    </v-col>
    <v-col cols="12">
      <text-field-label :label="$t('agent.agent.field.instagram.label')" />
      <v-text-field
        :value="value.instagram"
        :placeholder="$t('agent.agent.field.instagram.placeholder')"
        dense
        outlined
        required
        @input="change({ instagram: $event })" />
    </v-col>
    <v-col cols="12">
      <text-field-label :label="$t('agent.agent.field.address.label')" />
      <v-textarea
        :value="value.resellerAddress"
        :placeholder="$t('agent.agent.field.address.placeholder')"
        dense
        outlined
        @input="change({ resellerAddress: $event })" />
    </v-col>
    <v-col cols="12">
      <text-field-label :label="$t('agent.agent.field.tax.label')" />
      <v-text-field
        :value="value.taxId"
        :placeholder="$t('agent.agent.field.tax.placeholder')"
        dense
        outlined
        required
        @input="change({ taxId: $event })" />
    </v-col>
    <v-col
      cols="12">
      <div class="d-flex align-center">
        <title-label :label="$t('agent.agent.field.user.label')" />
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              x-small
              icon
              color="primary"
              v-bind="attrs"
              v-on="on">
              <v-icon>
                mdi-help-circle-outline
              </v-icon>
            </v-btn>
          </template>
          <p class="mb-0">
            {{ $t('agent.agent.tooltipUser') }}
          </p>
        </v-tooltip>
        <v-btn
          v-if="!users.length"
          class="mx-3"
          color="primary"
          depressed
          rounded
          @click="addObjArray(value.users)">
          <v-icon
            left
            small>
            mdi-plus-circle
          </v-icon>
          {{ $t('agent.agent.btn.addUser') }}
        </v-btn>
      </div>
    </v-col>
    <v-col
      v-if="users.length"
      cols="12"
      lg="5">
      <text-field-label
        :label="$t('agent.agent.field.userEmail.label')"
        required />
    </v-col>
    <v-col
      v-if="users.length"
      cols="12"
      lg="5">
      <text-field-label
        :label="$t('agent.agent.field.userPassword.label')"
        required />
    </v-col>
    <template v-for="(user, i) in value.users">
      <v-col
        v-if="!user.isDeleted"
        :key="`user_email-${i}`"
        cols="12"
        lg="5">
        <v-text-field
          :value="user.email"
          :placeholder="$t('agent.agent.field.userEmail.placeholder')"
          :rules="rule.email"
          :error-messages="error.users.email"
          :disabled="user.id !== null"
          dense
          outlined
          required
          @input="change({ users: updateObjArray(value.users, i, { email: $event }) })" />
      </v-col>
      <v-col
        v-if="!user.isDeleted"
        :key="`user_password-${i}`"
        cols="12"
        lg="5">
        <div class="d-flex">
          <v-text-field
            v-if="!user.id"
            :value="user.password"
            :placeholder="$t('agent.agent.field.userPassword.placeholder')"
            :rules="rule.password"
            :error-messages="error.users.password"
            type="password"
            dense
            outlined
            required
            @input="change({ users: updateObjArray(value.users, i, { password: $event }) })" />
          <v-text-field
            v-else
            value="xxxxxx"
            :placeholder="$t('agent.agent.field.userPassword.placeholder')"
            type="password"
            disabled
            dense
            outlined
            required />
        </div>
      </v-col>
      <v-col
        v-if="!user.isDeleted"
        :key="`user_delete-${i}`"
        cols="12"
        lg="2">
        <v-btn
          class="mx-3"
          color="red"
          icon
          @click="change({ users: updateObjArray(value.users, i, { isDeleted: true }) })">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import Upload from '@/components/Form/Upload.vue'
import { GET_TIERS } from '@/resources/graphql'

export default {
  apollo: {
    tiers: () => ({
      query: GET_TIERS
    })
  },
  components: {
    Upload
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        pictureUrl: null,
        resellerCode: null,
        name: null,
        tierId: null,
        tel: null,
        lineId: null,
        facebook: null,
        instagram: null,
        resellerAddress: null,
        taxId: null,
        users: []
      })
    },
    error: {
      type: Object,
      default: () => ({
        users: {
          email: [],
          password: []
        }
      })
    }
  },
  data () {
    return {
      tiers: {
        nodes: []
      },
      rule: {
        resellerCode: [
          (v) => !!v || this.$t('app.validate.required')
        ],
        name: [
          (v) => !!v || this.$t('app.validate.required')
        ],
        tierId: [
          (v) => !!v || this.$t('app.validate.required')
        ],
        email: [
          (v) => !!v || this.$t('app.validate.required'),
          (v) => /.+@.+\..+/.test(v) || this.$t('app.validate.email')
        ],
        password: [
          (v) => !!v || this.$t('app.validate.required'),
          (v) => (v && v.length >= 6) || this.$t('app.validate.max', [6])
        ]
      }
    }
  },
  computed: {
    users () {
      return this.value.users.filter((item) => !item.isDeleted)
    }
  },
  methods: {
    change (obj) {
      this.$emit('input', { ...this.value, ...obj })
    },
    addObjArray (oldUsers) {
      oldUsers.push({
        id: null,
        email: '',
        password: ''
      })
      return oldUsers
    },
    updateObjArray (array, index, newValue) {
      const newArray = array
      newArray[index] = {
        ...newArray[index],
        ...newValue
      }
      return newArray
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        v-bind="attrs"
        v-on="on">
        {{ $t('agent.agent.btn.recovery') }}
      </v-btn>
    </template>
    <v-card
      class="app-card"
      tile>
      <v-toolbar
        flat
        dense>
        <v-spacer />
        <v-btn
          icon
          small
          @click="closeDialog()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title class="headline font-weight-regular">
        {{ $t('app.dialog.recovery.title') }}
      </v-card-title>
      <v-card-text>
        {{ $t('app.dialog.recovery.description') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog()">
          {{ $t('app.btn.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          depressed
          rounded
          :disabled="loading"
          @click="submit($event)">
          <v-progress-circular
            v-if="loading"
            class="mr-2"
            size="16"
            width="2"
            indeterminate />
          {{ $t('agent.agent.btn.recovery') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UPDATE_RESELLER } from '@/resources/graphql'

export default {
  props: {
    itemResellerId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      this.loading = true
      const res = await this.$apollo.mutate({
        mutation: UPDATE_RESELLER,
        variables: {
          input: {
            id: Number(this.itemResellerId),
            activated: true
          }
        }
      })
      this.loading = false
      if (res) {
        const { data: { updateReseller } } = res
        if (updateReseller.errors && Object.keys(updateReseller.errors).length) {
          this.state = 'error'
        } else {
          this.state = 'success'
          this.$emit('submit')
          this.closeDialog()
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>
